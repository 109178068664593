textarea.form-control {
  min-height: 10rem;
}

.subscriber-form {
  input {
    color: $black;
    background: none;
    border: none;
    border-bottom: 1px solid $black;

    &::placeholder {
      color: $black;
      text-transform: uppercase;
      @include font-size(12px);
      letter-spacing: 2px;
    }

    &:focus {
      background: none;
      outline: none;
      box-shadow: none;
      border-color: $primary;
    }
  }

  .btn {
    position: absolute;
    right: 0;
    transition: $transition-base;

    &:hover {
      transform: translateX(1rem);

      svg {
        fill: $primary;
      }
    }
  }

  &.was-validated .form-control:invalid, .form-control.is-invalid {
    background-position: left center!important;
    padding: 0 2rem;
  }
}

.event-signup-form {
  .form-control {
    background-color: $light-bg;
  }

  .form-floating {
    label {
      letter-spacing: 1px;
      background: $light-bg;
      width: auto;
      height: auto;
      margin: 0.7rem 0.5rem;
      padding: 0 0.5rem;
    }
  }

  .form-check {
    a {
      color: $black;

      &:hover {
        color: $primary;
      }
    }
  }
}

.form-check {
  //gap: 8px;
  display: flex;
  //align-items: center;
}

.form-check-input {
  margin-top: 0;
  margin-right: 8px;
  min-width: 20px;
  min-height: 20px;
}


.radio-wrapper {
  height: 100%;

  input {
    position: absolute;
    top: 1.1rem;
    left: 0.8rem;
    margin: 0;
  }

  label {
    width: 100%;
    height: 100%;
    padding: 1rem 1rem 1rem 2.5rem;
    border: 1px solid $black;
    font-weight: 500;
  }
}
