.c-card-news {
  a {
    flex: 1;
  }

  a, h3 {
    transition: $transition-base;
  }

  &__content {
    // flex: 1;
  }

  &__info {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__btn {
    span {
      background: $primary;
      width: 2rem;
      height: 2rem;
      border-radius: $border-radius-xl;
      transition: $transition-base;

      svg {
        fill: $white;
        transform: rotate(-90deg);
      }
    }
  }

  a:hover {
    h3 {
      color: $primary;
    }

    .c-card-news__btn {
      span {
        background-color: $secondary;
        transform: translateX(1rem);
      }
    }
  }
}
