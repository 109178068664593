main#page-contact {

  .form-floating {
    label {
      letter-spacing: 1px;
      background: $white;
      width: auto;
      height: auto;
      margin: 0.7rem 0.5rem;
      padding: 0 0.5rem;
    }
  }

  .form-check {
    a {
      color: $black;

      &:hover {
        color: $primary;
      }
    }
  }

  .contact_info {
    a {
      color: $black;
      transition: $transition-base;

      &:hover {
        color: $primary;
      }
    }

    li {
      div {
        transition: $transition-base;
      }

      &:hover {
        div {
          transform: translateX(0.5rem);
        }
      }
    }

    &_links {
      margin-left: 2rem;
      gap: 0.4rem;

      a {
        text-decoration-color: $primary;
        display: inline-block;
      }
    }
  }
}
