main#page-events-detail {
  .e-event-infoblock {
    width: 100%;
    color: $white;
    background-color: $black;
    @include media-breakpoint-up(lg) {
      background-color: rgba($black, 0.8);
      //position: absolute;
      //top: 20%;
      //right: 300px;
    }
  }

  .event-tag {
    border: 1px solid $primary;
    color: $primary;
    padding: 0.5rem 2rem;
    transition: $transition-base;

    &:hover {
      background-color: $primary;
      color: $white;
    }
  }

  .event-contact-location{
    svg{
      margin-right: 0.3rem;
    }
  }

  .event-contact-info{
    svg{
      margin-right: 0.3rem;
    }
    li{
      a{
        color: $black;
        transition: $transition-base;
        &:hover{
          color: $primary;
        }
      }
    }
  }

  #event-map{
    width: 100%;
    height: 20rem;
  }
}

main#page-events-summary{
  .summary-img{
    max-height: 20rem;
  }
}

main#page-events-confirmation{
  .confirmation{
    height: 13rem;
    background-image: url("../../../static/img/thank_you.jpg");
    background-position: bottom;
    background-size: 50rem;
    background-repeat: repeat-x;
    @include media-breakpoint-down(md) {
      height: 7rem;
      background-size: 25rem;
    }
  }
  .container{
    //padding-top: 4rem;
    padding-bottom: 14rem;
    @include media-breakpoint-down(md) {
      padding-top: 0;
      padding-bottom: 7rem;
    }
  }
}
