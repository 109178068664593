.page-widget {
  .document-list {
    display: grid;
    column-gap: 4rem;
    row-gap: 0.6rem;

    @include media-breakpoint-up(md) {
      grid-template-columns: repeat(auto-fill, minmax(auto, 1fr));
      grid-auto-flow: column;
      grid-template-rows: repeat(5, auto);
    }


    li {
      display: flex;
      align-items: center;
      position: relative;
      break-inside: avoid;

      a {
        color: $primary;
        align-items: start;
        transition: all ease 0.3s;

        svg {
          min-width: 20px;
          height: auto;
          fill: $dark;
          transition: $transition-base;
        }

        &:hover {
          color: darken($primary, 10);

          svg {
            fill: $primary;
          }
        }
      }
    }
  }
}
