.page-widget {
  table {
    tr {
      td {
        padding: 0.2rem 0;

        &:first-of-type {
          white-space: nowrap;
          padding-right: 0.5rem;
          font-weight: 500;
        }

        &:last-of-type {
          display: flex;
          align-items: center;
          gap: 0.5rem;

          &:before {
            display: flex;
            content: '';
            width: 5px;
            min-width: 5px;
            height: 5px;
            border-radius: 10rem;
            background-color: $primary;
          }
        }
      }
    }
  }
}
