.c-settlement{
  flex: 1;

  img{
    aspect-ratio: 1 / 1.1;
  }
  h3{
    transition: $transition-base;
  }
  .cms-text{
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &:hover{
    h3{
      color: $primary;
    }
  }
}
