.cms-text {
  word-break: break-word;
  @include font-size(18px);

  p a:not(.btn) {
    text-decoration: underline;
  }

  p:last-of-type {
    margin-bottom: 0;
  }

  ol,
  ul {
    margin-top: $grid-gutter-width * 0.5;
    margin-bottom: $grid-gutter-width * 0.5;
  }
}

.cms_inline_photo {
  width: 100%;

  img {
    width: 100%;
    max-width: 100%;
  }
}

.fw-medium {
  font-weight: 500;
}

.ls-1 {
  letter-spacing: 1px;
}

.ls-2 {
  letter-spacing: 2px;
}

.ls-3 {
  letter-spacing: 3px;
}

.text-balance {
  text-wrap: balance;
}
