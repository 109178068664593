.l-widget--quote{
  cite{
    &:before, &:after{
      display: block;
      content: '';
      width: 2rem;
      height: 1px;
      background: $primary;
    }
  }
}
