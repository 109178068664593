$anim-speed: 0.25s;
$easing-in: ease-out;
$easing-out: ease-in;

.a-hover-zoom-img {
  display: inline-block;
  overflow: hidden;

  img {
    transition: transform $anim-speed $easing-in;
  }

  &:hover img {
    transform: scale(1.1);
    transition-timing-function: $easing-out;
  }
}
