.gallery-grid {
  column-count: 3;
  column-gap: 1rem;
  row-gap: 1rem;

  @include media-breakpoint-down(md) {
    column-count: 2;
  }

  &-item {
    display: block;
    width: 100%;
    height: 100%;
    break-inside: avoid;
  }
}
