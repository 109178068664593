.has-dropdown {
  position: relative;

  &:last-of-type {
    .small_menu {
      transform: translate(-25%, -0%);

      @include media-breakpoint-down(xl) {
        transform: translate(-40%, -0%);
      }
    }
  }
}

.mega-dropdown {
  position: static;
}

.has-dropdown.open {
  .mega_menu {
    visibility: visible !important;
  }
}

.mega_menu {
  left: 0;
  right: 0;

  .mega_wrapper {
    width: auto;
  }

  .menu_heading {
    svg {
      fill: $white;
    }
  }

  ul li {
    margin-bottom: 0.2rem;
  }

  a {
    font-size: 1rem;
    color: $white;
    text-decoration: none;

    &:hover {
      color: $primary;
    }
  }
}


//SMALL MENU//
.small_menu {
  width: 100%;
  min-width: 15rem;

  a {
    color: $white;
    text-decoration: none;

    &:hover {
      color: $primary;
    }
  }
}

//DESKTOP RESPONSIVE//
@media (min-width: 992px) {
  .mega_menu, .small_menu {
    background: $light-bg;
    visibility: hidden;
    opacity: 0;
    position: absolute;
    margin-top: 1.27rem;
    overflow: hidden;

    a {
      color: black;
    }

    .menu_heading {
      svg {
        fill: $black;
        transform: rotate(-90deg);
        transition: $transition-base;
      }

      &:hover {
        svg {
          fill: $primary;
          transform: rotate(-90deg) translateY(0.3rem);
        }
      }
    }
  }

  .has-dropdown.open {
    .mega_menu, .small_menu {
      visibility: visible;
      opacity: 1;
      z-index: 99;
    }
  }
}

