.filter-item:not(input[type='checkbox']) {
  background-color: $light-bg;
  padding: 0.75rem 2rem;
  border: none;
  height: 3rem;

  &.form-select {
    padding-right: 3rem;
    color: $black;
    text-transform: uppercase;
    @include font-size(12px);
    letter-spacing: 2px;

    option {
      text-transform: none;
    }
  }
}


.c-date-picker {
  min-width: 19.3rem;
  @include media-breakpoint-down(md) {
    min-width: 100%;
  }

  label {
    //cursor: pointer;
    line-height: 0;
    margin-right: 0.2rem;
    padding: 0.85rem 0.85rem;
    background: $black;
    transition: $transition-base;
  }

  .filter-item {
    padding: 0.85rem 0;

    &::placeholder {
      color: $black;
    }
  }
}

.flatpickr-calendar {
  .flatpickr-day.selected {
    background-color: $primary;
    border-color: $primary;

    &:hover {
      background-color: $primary;
      border-color: $primary;
    }
  }
}


