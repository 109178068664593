.btn {
  all: unset;
  cursor: pointer;

  &:focus, &:active {
    border: none;
    outline: none;
    background: none;
    box-shadow: none !important;
  }
}

.btn-primary, .btn-outline-primary {
  color: $primary;
  @include font-size(14px);
  letter-spacing: 2px;
  text-transform: uppercase;
  font-weight: 500;
  position: relative;
  transition: $transition-base;

  svg {
    opacity: 0;
    transition: $transition-base;
  }

  &:hover {
    background: none;
  }

  &.directional-left {
    svg {
      position: absolute;
      right: 0;
      top: 20%;

    }

    &:hover {
      transform: translateX(-1rem);

      svg {
        transform: translateX(1rem);
        opacity: 1;
      }
    }
  }

  &.directional-right {
    svg {
      position: relative;
      margin-bottom: 1.5px;
    }

    &:hover {
      svg {
        transform: translateX(0.3rem);
        opacity: 1;
      }
    }
  }
}


.btn-submit, .btn-submit:focus {
  box-sizing: border-box;
  padding: 0.75rem 2rem;
  background-color: $primary;
  color: $white;
  text-transform: uppercase;
  letter-spacing: 2px;
  @include font-size(14px);
  font-weight: 500;
  transition: $transition-base;

  &:hover {
    color: $white;
    background-color: $secondary;
  }
}


.btn-event {
  svg {
    transform: rotate(-90deg);
    transition: $transition-base;
  }

  &:hover {
    svg {
      transform: rotate(-90deg) translateY(0.5rem);
    }
  }
}


.btn-return {
  transition: $transition-base;

  svg {
    transform: rotate(90deg);
    transition: $transition-base;
  }

  &:hover {
    color: $primary;

    svg {
      transform: rotate(90deg) translateY(0.5rem);
      fill: $primary;
    }
  }
}

.btn-outline-primary {
  border: 2px solid $primary;

  &:hover{
    background-color: $primary;
    color: $white;
  }
}
