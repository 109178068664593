#page-videos {
  .line {
    content: '';
    display: flex;
    height: 10rem;
    margin-right: 1rem;
    width: 5px;
    background-color: $primary;

    @include media-breakpoint-down(md) {
      margin-right: 0.5rem;
    }
  }

  .featured-video {
    writing-mode: vertical-rl;
    text-orientation: mixed;
  }
}

.c-video {
  img {
    aspect-ratio: 16/9;
    object-fit: cover;
  }

  .play-btn {
    position: absolute;
    right: 5%;
    bottom: 10%;
    background-color: $primary;
    width: 3rem;
    min-width: 3rem;
    height: 3rem;
    min-height: 3rem;
    transition: all ease 0.3s;
  }

  &:hover {
    .play-btn {
      transform: scale(0.98);
      border-radius: 8px;
      background-color: darken($primary, 5);
    }
  }
}
