.l-widget-carousel {
  $swiper-button-font-size: 1.5rem;

  .swiper-text {
    padding-left: $swiper-button-font-size * 2;
    padding-right: $swiper-button-font-size * 2;
  }

  .swiper-button-prev::after,
  .swiper-button-next::after {
    font-size: $swiper-button-font-size;
  }

  .swiper-button-prev {
    left: $grid-gutter-width * 0.5;

    @include media-breakpoint-up(lg) {
      left: $grid-gutter-width * 2;
    }
  }

  .swiper-button-next {
    right: $grid-gutter-width * 0.5;

    @include media-breakpoint-up(lg) {
      right: $grid-gutter-width * 2;
    }
  }

  .swiper-pagination {
    bottom: $grid-gutter-width * 0.5;

    @include media-breakpoint-up(lg) {
      bottom: $grid-gutter-width * 2;
    }
  }

  .swiper-pagination-bullet {
    background: $white;
  }
}
