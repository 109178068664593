.w-banner{
  height: 31.25rem;

  &__overlay{
    @extend %coverer;
    background: rgba(0, 0, 0, 0.5) linear-gradient(318deg, rgba(255, 255, 255, 0) 0%, rgba(147, 118, 60, 0.3) 100%);
  }
}
.s-banner{
  height: 17rem;
  &__overlay{
    @extend %coverer;
  }
}
