#page-blog-detail {
  .blog-detail-image {
    background: linear-gradient(0deg, rgba($light-bg, 1) 50%, rgba($white, 1) 50%);
  }

  .cms-text {
    h1, h2, h3, h4, h5 {
      margin: 2rem 0;
    }
  }
}
