.c-event-block {
  //flex: 1;
  a {
    color: unset;
    text-decoration: none;
    transition: $transition-base;
    flex: 1;
  }

  &__date {
    background-color: $black;
  }

  &__img img {
    aspect-ratio: 5/3;
    object-fit: cover;
  }

  &__info {
    flex: 1;
  }

  //.btn-primary {
  //  color: $black;
  //}

  &:hover {
    color: $primary;
  }
}


.c-event-list {
  a {
    color: unset;
    text-decoration: none;
    width: 100%;
  }

  &__date {
    min-width: 6.5rem;
    min-height: 6.5rem;
    background: $black;
    color: $white;
    //padding: 1.5rem 2rem;
    @include font-size(20px);
    line-height: 1.7rem;

    &__day {
      padding-bottom: 0.4rem;
      @include font-size(40px);
    }
  }

  &__info {
    .cms-text {
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &__label {
    @include font-size(12px);
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 2px;
    display: inline-flex;
    margin-bottom: 1rem;
    background: $primary;
    color: $white;
    padding: 0.3rem 1rem;
  }

  &__price {
    min-width: 6.5rem;
    background: $primary;
    padding: 0.2rem 1rem;
    @include font-size(14px);
    font-weight: 500;
    color: $white;
  }

  img {
    min-width: 300px;
    height: 350px;
    object-fit: cover;
    @include media-breakpoint-down(md) {
      width: 100% !important;
      height: 30rem;
      object-fit: cover;
    }
  }
}


.b-event-filters {
  @include media-breakpoint-up(lg) {
    height: 100vh;
    position: sticky;
    top: 2rem;
    border-left: 1px solid $border-color;
  }

  @media (min-width: 992px) {
    .offcanvas {
      position: relative;
      overflow-y: auto;
      z-index: auto;
      flex-grow: 1;
      width: auto !important;
      height: auto !important;
      max-height: 100%;
      visibility: visible !important;
      background-color: transparent !important;
      border: 0 !important;
      transform: none !important;
      transition: none;
    }
  }
}
