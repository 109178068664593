.l-errors {
  $bg: $light-bg;
  $img-size: 25rem;

  display: flex;
  align-items: center;
  justify-content: center;
  background: $bg;
  min-height: 100vh;

  img {
    width: 25rem;
    max-width: 75%;
  }
}
