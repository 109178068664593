.l-site-nav {
  $nav-breakpoint: 'lg';
  $offcanvas-width: 24rem;
  $offcanvas-top: 0; // could also be navbar height
  $offcanvas-bg-color: $black;
  $offcanvas-y: 2.5rem;
  $offcanvas-nav-link-padding-x: 0.625rem;
  $offcanvas-nav-link-padding-y: 1.625rem;
  $offcanvas-active-bg: $primary;

  position: relative;
  z-index: $zindex-offcanvas;
  background-color: $black;

  .navbar-brand img {
    @include media-breakpoint-down($nav-breakpoint) {
      width: 80%;
    }
  }

  .nav-link {
    color: $white;

    &:hover {
      color: $primary;

      svg {
        fill: $primary;
      }
    }

    @include media-breakpoint-up(md) {
      white-space: nowrap;
    }

    @include media-breakpoint-down(xl){

    }
  }

  .top-nav, .bottom-nav {
    @include media-breakpoint-down($nav-breakpoint) {
      display: none;
    }
  }

  .bottom-nav .nav-link {
    cursor: pointer;

    @include media-breakpoint-only(lg) {
      font-size: 12px !important;
    }
    @include media-breakpoint-down(xl){
      padding-left: 0.5rem;
      padding-right: 0.5rem;
    }
  }


  .hamburger {
    .hamburger-inner, .hamburger-inner:after, .hamburger-inner:before {
      background-color: $white;
      transition: $transition-base;
    }

    &:hover {
      .hamburger-inner, .hamburger-inner:after, .hamburger-inner:before {
        background-color: $primary;
      }

      opacity: 1;
    }
  }


  .offcanvas {
    border-left: 0;

    @include media-breakpoint-up($nav-breakpoint) {
      position: static;
      display: none;
      background: none;
      border: 0;
      transform: none;
      width: auto;
      margin-left: auto;
    }

    @include media-breakpoint-down($nav-breakpoint) {
      width: $offcanvas-width;
      background-color: $offcanvas-bg-color;
      padding-top: $offcanvas-y;
      padding-bottom: $offcanvas-y;
    }
  }

  .offcanvas .btn-close {
    @include media-breakpoint-up($nav-breakpoint) {
      display: none;
    }
  }

  .offcanvas .nav-link {
    @include media-breakpoint-down($nav-breakpoint) {
      padding: $offcanvas-nav-link-padding-x $offcanvas-nav-link-padding-y;
      &.active {
        background: $offcanvas-active-bg;
      }
    }

    &:hover {
      color: $primary;
    }
  }
}

.active {
  svg {
    fill: $primary;
  }

}
