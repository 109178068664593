@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";

// colors
$primary: #B5975D;
$secondary: #C7B594;

$light-bg: #fbf8f3;

$black: #080606;
$text: #353535;

$gray: #D1D1D1;

$theme-colors: (
  "primary": $primary,
  "secondary": $secondary,
  "success": $success,
  "info": $info,
  "warning": $warning,
  "danger": $danger,
  "light": $light-bg,
  "dark": $black,
  "gray": $gray,
);

// fonts
$font-family-headings: "Jost", sans-serif;
$font-family-base: "Jost", Helvetica, Arial, sans-serif;

// Custom
$container-fluid-max-width: 125rem;
$z-index-cookies: $zindex-modal - 1;

// Options
$enable-negative-margins: true;

// Spacing
$spacer: 0.625rem;
$spacers: (
  0: 0,
  1: $spacer,
  2: $spacer * 2,
  3: $spacer * 3,
  4: $spacer * 4,
  5: $spacer * 5,
  6: $spacer * 6,
  8: $spacer * 8,
  10: $spacer * 10,
  12: $spacer * 12,
);

// Grid columns
$grid-gutter-width: 2rem;
$container-padding-x: $grid-gutter-width * 0.625;
$gutters: $spacers;

$headings-line-height: 1.3;

$font-size-base:              1.063rem;
$h1-font-size: 1rem * 3.375;
$h2-font-size: 1rem * 1.75;
$h3-font-size: 1rem * 1.5;
$h4-font-size: 1rem * 1.5;
$h5-font-size: 1rem * 1.25;
$h6-font-size: 1rem;

$font-sizes: (
  1: $h1-font-size,
  2: $h2-font-size,
  3: $h3-font-size,
  4: $h4-font-size,
  5: $h5-font-size,
  6: $h6-font-size,
  10: 0.625rem,
  12: 0.75rem,
  14: 0.875rem,
  16: 1rem,
  18: 1.125rem,
);

$headings-margin-bottom: $spacer;
$headings-font-weight: 400;


// Navbar
$navbar-dark-color: $white;
$navbar-light-color: $black;

$navbar-nav-link-padding-x: 1rem;
$navbar-light-active-color: $primary;

// transition
$transition-base: all ease .3s;


//forms
$input-border-radius: 0;
$input-border-color: $black;
$input-focus-border-color: $primary;
$input-focus-box-shadow: none;
$input-border-width: 0.5px;


$form-label-color: $gray-600;
$form-label-font-size: 14px;

$form-floating-height: 45px;

$form-floating-padding-x: 1rem;
$form-floating-padding-y: 0.7rem;
$form-floating-input-padding-t: 0.5rem;
$form-floating-label-transform: scale(.85) translateY(-1.5rem) translateX(.15rem);
$form-floating-transition: transform .1s ease-in-out;
$form-floating-label-opacity: 1;


$form-select-border-radius: 0;
$form-select-border-color: $input-border-color;
$form-select-padding-y: 0.7rem;
//$form-select-color: $gray-600;
$form-select-disabled-color: $gray-600;

$form-select-focus-border-color: $primary;
$form-select-focus-box-shadow: none;
$form-select-border-width: $input-border-width;


$form-check-input-border-radius: 0;
$form-check-input-checked-bg-color: $primary;
$form-check-input-focus-border: $primary;
$form-check-input-checked-border-color: $primary;
$form-check-input-focus-box-shadow: none;
$form-check-input-width: 20px;


// scss-docs-start pagination-variables
$pagination-padding-y: .375rem !default;
$pagination-padding-x: .75rem !default;
$pagination-padding-y-sm: .25rem !default;
$pagination-padding-x-sm: .5rem !default;
$pagination-padding-y-lg: .75rem !default;
$pagination-padding-x-lg: 1.5rem !default;

$pagination-font-size: $font-size-base !default;

$pagination-color: $text;
$pagination-border-radius: none;
$pagination-border-width: none;
$pagination-border-color: $gray-300 !default;

$pagination-focus-color: $primary;
$pagination-focus-bg: none;
$pagination-focus-box-shadow: none;

$pagination-hover-color: $primary;
$pagination-hover-bg: none;
$pagination-hover-border-color: none;

$pagination-active-color: $component-active-color !default;
$pagination-active-bg: $primary;
$pagination-active-border-color: $primary;

$pagination-disabled-color: $gray-600 !default;
$pagination-disabled-bg: $white !default;
$pagination-disabled-border-color: $gray-300 !default;

$pagination-transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out !default;


$tooltip-max-width:                 300px;
$tooltip-color:                     $white !default;
$tooltip-bg:                        $black;
$tooltip-border-radius:             0;
$tooltip-opacity:                   1;
$tooltip-padding-y:                 $spacer;
$tooltip-padding-x:                 $spacer * .5 !default;
