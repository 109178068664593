main#page-locations {

  .location-img {
    aspect-ratio: 1/1;
    object-fit: cover;
  }

  .contact_info {
    min-width: 300px;

    @include media-breakpoint-down(lg) {
      min-width: 100%;
    }

    a {
      color: $black;

      &:hover {
        color: $primary;
      }
    }
  }
}
