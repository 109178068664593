.l-site-footer {
  &__icons {
    svg {
      transition: $transition-base;
    }

    svg:hover {
      fill: $primary;
    }
  }

  ul li {
    a {
      color: $white;
      transition: $transition-base;
    }

    &:hover a {
      color: $primary;
    }

  }

  &__sub{
    border-top: 1px solid $gray-900;
  }
}
