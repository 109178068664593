#page-home {
  .b-hero {
    max-height: 85vh;
    min-height: 48.125rem;

    img {
      width: 100%;
      max-height: 85vh;
      min-height: 48.125rem;
    }

    &__overlay {
      @extend %coverer;
      //background: rgba(0, 0, 0, 0.10) linear-gradient(318deg, rgba(255, 255, 255, 0) 0%, rgba(147, 118, 60, 0.1) 100%);
    }

    //&__content{
    //  @extend %centerer;
    //}
    &__cta {
      padding-top: 4rem;
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.8) 0%, rgba(255, 255, 255, 0) 100%);
      &__box {
        transition: $transition-base;
        border-bottom: 6px solid rgba(255, 255, 255, 0);
        margin-bottom: -3px;

        &:nth-child(2) {
          border-left: 1px solid rgba(255, 255, 255, 0.1);
          border-right: 1px solid rgba(255, 255, 255, 0.1);
        }

        a {
          color: $white;
          text-decoration: none;
          text-align: center;

          h2 {
            letter-spacing: 2px;
            transition: $transition-base;
          }

          span {
            display: inline-block;
            letter-spacing: 1px;
            max-width: 20rem;
            line-height: 160%;
          }
        }

        &:hover {
          border-bottom-color: $primary;

          h2 {
            color: $primary;
          }
        }
      }
    }
  }

  .s-news {
    @include media-breakpoint-only(md) {
      .c-card-news:last-of-type {
        display: none !important;
      }
    }
  }
}
